import React , {useState, useEffect} from 'react';
import {FaBars} from 'react-icons/fa'
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';

import {Nav, NavbarContainer, Navlogo,MobileIcon, NavMenu, NavItem, NavLink, NavBtn, NavBtnLink } from './NavbarElements';


const Navbar = ({ toggle }) => {

    const [scrollNav, setScrollNav] = useState(false)

    const changeNav = () => {
        if(window.scrollY >= 80){
            setScrollNav(true)
        } else{
            setScrollNav(false)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll',changeNav)
    },[]);

    const toggleHome = () => {
        scroll.scrollToTop()
    }
    return (
        <>
        <IconContext.Provider value={{ color: '#fff'}}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <Navlogo to='/' onClick={toggleHome}>C.P.W.A</Navlogo>
                    <MobileIcon onClick={toggle}>
                        <FaBars />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLink to='about'
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            >
                                Who We Are</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='services'
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            >Office Bearers </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='process'
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            >Notice Board</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink to='discover'
                            smooth={true} duration={500} spy={true}
                            exact='true' offset={-80}
                            >Membership</NavLink>
                        </NavItem>
                    </NavMenu>
                    <NavBtn>
                        <NavBtnLink to="/Download-Constitution"
                        smooth={true} duration={500} spy={true}
                        exact='true' offset={-80}
                        > Download C.P.W.A Constitution</NavBtnLink>
                    </NavBtn>
                </NavbarContainer>
            </Nav>
            </IconContext.Provider>
        </>
    )
}

export default Navbar;
