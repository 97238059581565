import React from 'react';
import Icon4 from '../../images/TheChairman.png';
import Icon5 from '../../images/ViceChair.jpg';
import Icon6 from '../../images/secretary.jpg';
import Icon7 from '../../images/treasurer.png';
import { ServicesContainer, ServicesWrapper,  ServicesCard, ServicesH1, ServicesH2,ServicesH3, ServicesP, ServicesIcon  } from './servicesElements'
const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1> The Society Committee </ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon img src={Icon4} />
                    <ServicesH2>Chairman</ServicesH2>
                    {/* <ServicesH3>Web Applications and Design</ServicesH3>
                    <ServicesH3>Mobile application</ServicesH3>
                    <ServicesH3>Graphic Design</ServicesH3> */}
                    <ServicesP> The Association chairman is part of the executive committee and a key office bearers of the association. The chairman is responsible for presiding over the association commitee meetings, all general meetings and prove any mergence case.</ServicesP>
                </ServicesCard>

                <ServicesCard>
                    <ServicesIcon img src={Icon5} />
                    <ServicesH2> Vice Chairman</ServicesH2>
                    {/* <ServicesH3>Server maintenance</ServicesH3>
                    <ServicesH3>Network Maintenance</ServicesH3>
                    <ServicesH3>Firewall/ Network Security</ServicesH3>
                    <ServicesH3>VPN and Remote Access</ServicesH3>
                    <ServicesH3>24/7 Monitoring</ServicesH3> */}
                    <ServicesP>Perfoms any duties of the chairman in his absence.  </ServicesP>
                </ServicesCard>

                <ServicesCard>
                    <ServicesIcon img src={Icon6} />
                    <ServicesH2> Secretary </ServicesH2>
                    {/* <ServicesH3> Cloud and Email Services </ServicesH3>
                    <ServicesH3>Email Service Setup and Migration</ServicesH3>
                    <ServicesH3>Access Data Anywhere</ServicesH3>
                    <ServicesH3>Email Retention, Archiving, and Ownership</ServicesH3>
                    <ServicesH3>Backup solutions</ServicesH3> */}
                    <ServicesP> Deals with all the correspondence of the society under the general supervision of the commitee. </ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon img src={Icon7} />
                    <ServicesH2> Treasurer </ServicesH2>
                    {/* <ServicesH3> Cloud and Email Services </ServicesH3>
                    <ServicesH3>Email Service Setup and Migration</ServicesH3>
                    <ServicesH3>Access Data Anywhere</ServicesH3>
                    <ServicesH3>Email Retention, Archiving, and Ownership</ServicesH3>
                    <ServicesH3>Backup solutions</ServicesH3> */}
                    <ServicesP> Receiving and disbursement of all money belong to the society under the instruction of the commitee. </ServicesP>
                </ServicesCard>
            </ServicesWrapper>
            

            
        </ServicesContainer>
    )
}

export default Services
