import React from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa'
import { 
    FooterContainer, 
    FooterWrap, 
    FooterLinkContainer, 
    FooterLinkWrapper, 
    FooterLinkItems, 
    FooterLinkTitle, 
    FooterLink,
    SocialMedia,
    SocialMediaWrap,
    SocialLogo,
    WebsiteRights,
    SocialIcons,
    SocialIconLink
 } from './FooterElements'

 const toggleHome = () => {
    scroll.scrollToTop()
 };
    

const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrap>
                <FooterLinkContainer>
                    <FooterLinkWrapper>
                    <FooterLinkItems>
                        <FooterLinkTitle>Quick Links</FooterLinkTitle>
                            <FooterLink to ="/">Who We Are</FooterLink>
                            <FooterLink to ="/">Committee</FooterLink>
                            <FooterLink to ="/">Notice Board</FooterLink>
                            <FooterLink to ="/">Membership</FooterLink>
                            <FooterLink to ="/">Terms of Services</FooterLink>
                    </FooterLinkItems>

                    <FooterLinkItems>
                        <FooterLinkTitle>Our Partners</FooterLinkTitle>
                            <FooterLink to ="/">County Government</FooterLink>
                            <FooterLink to ="/">Evabo Enterprice</FooterLink>
                            <FooterLink to ="/">Repay Security Services Ltd</FooterLink>
                            
                    </FooterLinkItems>
                    </FooterLinkWrapper>

                    <FooterLinkWrapper>

                    <FooterLinkItems>
                        <FooterLinkTitle>Contact Us</FooterLinkTitle>
                            <FooterLink to ="#">cityparkwelfare@gmail.com</FooterLink>
                            <FooterLink to ="/">0713904010</FooterLink>
                            <FooterLink to ="/">Parklands, Nairobi</FooterLink>
                            
                    </FooterLinkItems>
                    </FooterLinkWrapper>

                </FooterLinkContainer>
                <SocialMedia>
                    <SocialMediaWrap>
                        <SocialLogo to='/' onClick={toggleHome}>
                            C.P.W.A
                        </SocialLogo>
                        <WebsiteRights>&copy; {new Date().getFullYear()} All right reserved</WebsiteRights> 
                        <SocialIcons>
                            <SocialIconLink href="/" target="_blank"
                            aria-label="Facebook">
                                <FaFacebook />
                            </SocialIconLink>

                            <SocialIconLink href="/" target="_blank"
                            aria-label="Instagram">
                                <FaInstagram />
                            </SocialIconLink>

                            <SocialIconLink href="/" target="_blank"
                            aria-label="Twitter">
                                <FaTwitter />
                            </SocialIconLink>

                            <SocialIconLink href="/" target="_blank"
                            aria-label="Youtube">
                                <FaYoutube />
                            </SocialIconLink>
                        </SocialIcons>
                    </SocialMediaWrap>
                </SocialMedia>
            </FooterWrap>
            
        </FooterContainer>
    )
}

export default Footer
