import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

// Blinking animation
const blinkAnimation = keyframes`
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
`;

export const Container = styled.div`
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
    background: linear-gradient( 
        108deg,
        rgba(1, 147, 86, 1) 0%,
        rgba(10, 201, 122, 1) 100%
    );
`;

export const FormWrap = styled.div`
    height: 100%auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 400px){
        height: 80%auto;
    }
`;

export const Icon = styled(Link)`
    margin-left: 32px;
    margin-top: 32px;
    text-decoration: none;
    color: #fff;
    font-weight: 700;
    font-size: 32px;

    @media screen and (max-width: 480px){
        margin-left: 16px;
        margin-top: 8px;
    }
`;

// Removed FormContent, Form, FormH1, FormLabel, FormInput

export const FormButton = styled.button`
    background: #fff;
    padding: 16px 0;
    border: none;
    border-radius: 4px;
    color: #01bf7;
    font-size: 20px;
    cursor: pointer;
    animation: ${blinkAnimation} 1.5s infinite; // Apply the blinking animation
    transition: background 0.3s ease;
    text-decoration: none;

    &:hover {
        background: #019f5f; // Darken the button on hover
    }
`;

export const Text = styled.span`
    text-align: center;
    margin-top: 24px;
    color: #01bf7;
    text-decoration: none;
    font-size: 14px;
`;
