import React from 'react';
import Icon1 from '../../images/Till.png';
import Icon2 from '../../images/estateRules.png';
import Icon3 from '../../images/CountyBylaws.png';
import { 
    ProcessContainer, 
    ProcessWrapper,  
    ProcessCard, 
    ProcessH1, 
    ProcessH2, 
    ProcessP, 
    ProcessIcon 
} from './ProcessElements';

const Process = () => {
    return (
        <ProcessContainer id="process">
            <ProcessH1> 
                Updates
            </ProcessH1>
            <ProcessWrapper>
                <ProcessCard>
                    <ProcessIcon img src={Icon1} />
                    <ProcessH2>Till Number</ProcessH2>
                    <ProcessP>
                        All payments to the Society should be paid through the association Safaricom till number or Paramount Bank Account.
                    </ProcessP>
                </ProcessCard>

                <ProcessCard>
                    <ProcessIcon img src={Icon2} />
                    <ProcessH2>Estate Rules</ProcessH2>
                    <ProcessP>
                        The main objective of the development of the City Park Estate has been the provision of a high-quality lifestyle for the estate residents. We believe in good neighbourliness; ensure you adhere to the set Estate Rules and Regulations. 
                        <a href='/documents/estateRules.pdf' target='_blank' rel='noopener noreferrer'> Read More</a>
                    </ProcessP>
                </ProcessCard>

                <ProcessCard>
                    <ProcessIcon img src={Icon3} />
                    <ProcessH2>Other Developments</ProcessH2>
                    <ProcessP>
                        There is new released Nairobi City County Laws. 
                        <a href='https://nairobi.go.ke/county-laws/' target='_blank' rel='noopener noreferrer'> Read More</a>
                    </ProcessP>
                </ProcessCard>
            </ProcessWrapper>
        </ProcessContainer>
    );
}

export default Process;
