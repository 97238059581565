import React from 'react';
import { 
    Container, 
    FormButton, 
    FormWrap, 
    Icon 
} from './ReachUsElements';

const ReachUs = () => {
    return (
        <>
            <Container>
                <FormWrap>
                    <Icon to="/">C.P.W.A (City Park Welfare Association)</Icon>
                    <FormButton 
                        as="a" 
                        href='/documents/citypark-welfare-association.pdf' 
                        target='_blank' 
                        rel='noopener noreferrer' 
                        style={{ 
                            margin: '20px auto', // Centers the button
                            display: 'block', // Makes the button block-level
                            padding: '10px 20px', // Adds padding for a better look
                            fontSize: '16px' // Adjusts font size
                        }} 
                    >
                        DOWNLOAD THE SOCIETY RULES AND REGULATIONS
                    </FormButton>
                </FormWrap>
            </Container>
        </>
    );
}

export default ReachUs;
