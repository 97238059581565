import React from 'react';
import { Button } from '../ButtonElements';
import { 
    InforContainer,
    InforWrapper, 
    InforRow, 
    Column1, 
    Column2,
    TextWrapper, 
    TopLine, 
    Heading, 
    Substitle, 
    BtnWrap, 
    ImgWrap, 
    Img
} from './InforElements';
import whoWeAreImg from '../../images/who-we-are.jpg'; // Import image directly
import membersImg from '../../images/members.jpg'; // Import another image

const InforSection = ( {lightBg, id, imgStart, topLine, lightText, headLine, darkText, description, buttonLabel, 
    imgType, alt, primary,
    dark,
    dark2} ) => {
    
    // Determine which image to use
    const displayImg = imgType === 'whoWeAre' ? whoWeAreImg : membersImg;

    return (
        <>
            <InforContainer lightBg={lightBg} id={id}>
                <InforWrapper>
                    <InforRow imgStart={imgStart}>
                        <Column1>
                            <TextWrapper>
                                <TopLine>{topLine}</TopLine>
                                <Heading lightText={lightText}>{headLine}</Heading>
                                {/* Rendering description with embedded HTML using dangerouslySetInnerHTML */}
                                <Substitle darkText={darkText} dangerouslySetInnerHTML={{__html: description}}></Substitle>
                                {/* <BtnWrap>
                                    {buttonLabel && (
                                        <Button to='home'
                                            smooth={true}
                                            duration={500}
                                            spy={true}
                                            exact="true"
                                            offset={-80}
                                            primary={primary ? 1 : 0}
                                            dark={dark ? 1 : 0}
                                            dark2={dark2 ? 1 : 0}
                                        >
                                            {buttonLabel}
                                        </Button>
                                    )}
                                </BtnWrap> */}
                            </TextWrapper>
                        </Column1>
                        <Column2>
                            <ImgWrap>
                                <Img src={displayImg} alt={alt} />
                            </ImgWrap>
                        </Column2>
                    </InforRow>
                </InforWrapper>
            </InforContainer>
        </>
    );
}

export default InforSection;
