import React from 'react';
import imageFile from '../../images/Citypark-road.jpg'; // Import the image file
import {
  HeroContainer,
  HeroBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroP2,
} from './HeroElements';

const HeroSection = () => {
  return (
    <HeroContainer>
      <HeroBg>
        {/* Replace video with img */}
        <img src={imageFile} alt="Citypark Road" id="hero-image" />
      </HeroBg>
      <HeroContent>
        <HeroH1>
        The principal activity of the society is to ensure the welfare of the
          owners and occupiers of City Park Estate, Nairobi. We are a registered
          association under the Registrar of Societies, operating within
          established rules and guidelines.
        </HeroH1>
        <HeroP>
        The association is registered with the Registrar of Society and operates within the set rules and procedures.
        </HeroP>
        <HeroP2>
        The society is not liable to taxation. Its revenue is derived from contributions from the members for the services rendered to secure and mantain the estate..
        </HeroP2>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
