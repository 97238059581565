import React, {useState} from 'react'
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
import InforSection from '../components/InforSection';
import { homeObjOne, homeObjThree, homeObjTwo } from '../components/InforSection/Data';
import Navbar from '../components/Navbar'
import Process from '../components/Process';
import Services from '../components/Services';
import Sidebar from '../components/Sidebar'



const Home = () => {
        const [isOpen, setIsOpen] = useState(false);

        const toggle = () => {

            setIsOpen(!isOpen);
        };
            








    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Navbar toggle={toggle} />
            <HeroSection />
            
            <InforSection  {...homeObjOne} />
            <Services />
            {/* <InforSection  {...homeObjTwo} /> */}
            <Process />
            <InforSection  {...homeObjThree} />
            
            <Footer />
        </>
    );
};

export default Home
