import React from 'react'
import { 
    SidebarContainer, 
    Icon,
    CloseIcon, 
    SidebarWrapper, 
    SidebarMenu, 
    SidebarLink, 
    SidebarRoute, 
    SideBtnWrap 
} from './SidebarElements'

const Sidebar = ({ isOpen,toggle}) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
           <Icon onClick={toggle}>
               <CloseIcon />
           </Icon>
           <SidebarWrapper>
               <SidebarMenu>
                   <SidebarLink to='about'onClick={toggle}>Who We Are</SidebarLink>
                   <SidebarLink to='services'onClick={toggle}>Office Bearers</SidebarLink>
                   <SidebarLink to='process'onClick={toggle}>Notice Board</SidebarLink>
                   <SidebarLink to='discover'onClick={toggle}>Membership</SidebarLink>
               </SidebarMenu>
               <SideBtnWrap>
                   <SidebarRoute to='/Download-Constitution'>Download Constitution</SidebarRoute>
               </SideBtnWrap>
           </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar
