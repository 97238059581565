export const homeObjOne = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Society Overview',
    headLine: '',
    description: 'City Park Welfare Association was established in October 2015. The principal activity of the society is to maintain the general welfare of all owners or occupiers of properties and residences in City Park Estate, Nairobi. Our top priorities include ensuring the safety and security of residents, organizing garbage collection, maintaining cleanliness, and fostering a serene environment.',
    buttonLabel: 'Learn More',
    imgStart: true,
    imgType: 'whoWeAre', // Use this identifier for the image
    alt: 'Creative Team',
    dark: false,
    primary: false,
    darkText: true
};

export const homeObjThree = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Membership',
    headLine: '',
    // Use an anchor tag inside the description
    description: `Our Membership is renewed annually, with individual households subscribing for KSh 12,000 per year and corporate members subscribing for a minimum of KSh 24,000 per year. Our objectives are outlined in our Constitution. 
    <a href='/documents/citypark-welfare-association.pdf' target='_blank' rel='noopener noreferrer'>Download Constitution</a>. 
    To join the committee, register with us today.</a>`,
    imgStart: true,
    imgType: 'members', // Use this identifier for the image
    alt: 'Membership',
    dark: false,
    primary: false,
    darkText: true
};


